<template>
	<!-- nav -->
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="box">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10111')}}
							</h2>
						</div>
						<div class="inner__wrap inner__wrap--border">
							<div class="box box--reverse">
								<ul class="agrees">
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" :checked="allChecked" @change="allCheck($event)">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10112')}}</span>
											</label>
										</div>
									</li>
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" value="age" v-model="checkedList">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10113')}}</span>
											</label>
										</div>
									</li>
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" value="service" v-model="checkedList">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10114')}}</span>
											</label>
										</div>
										<router-link to="/layers/terms/service">
											<i class="icon icon-right--light">{{t('10115')}}</i>
										</router-link>
									</li>
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" value="private" v-model="checkedList">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10116')}}</span>
											</label>
										</div>
										<router-link to="/layers/terms/private">
											<i class="icon icon-right--light">{{t('10115')}}</i>
										</router-link>
									</li>
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" value="location" v-model="checkedList">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10117')}}</span>
											</label>
										</div>
										<router-link to="/layers/terms/location">
											<i class="icon icon-right--light">{{t('10115')}}</i>
										</router-link>
									</li>
									<li>
										<div class="checkbox">
											<label>
												<input type="checkbox" class="chk blind" value="push" v-model="checkedList">
												<span class="checkbox__bg"></span>
												<span class="agree__title">{{t('10118')}}</span>
											</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="!completed ? 'btn-disabled' : 'btn-confirm' " @click="nextStep()">{{t('10119')}}</button>
		</div>
	</div>
<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
<TheNavigation></TheNavigation>
</template>


<script>
import { computed, ref } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const value = ['age', 'service', 'private', 'location', 'push'];
		const checkedList = ref([]);
		const openErrorAlert = ref(false)
		const completed = computed(
			() => (['age', 'service', 'private']
				.filter(checked => checkedList.value.includes(checked)))
			.length === 3
		);

		const allChecked = computed(
			() => (['age', 'service', 'private', 'location', 'push']
				.filter(checked => checkedList.value.includes(checked)))
			.length === 5
		);

		const allCheck = (event) => {
			if (event.target.checked) {
				checkedList.value = value;
			} else {
				checkedList.value = [];
			}
		};

		const nextStep = () => {
			if(completed.value === true){
				router.push("/join/step2");
			} else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "필수 동의사항 모두 동의해주세요."});
				openErrorAlert.value = true;
			    //alert("필수 동의사항 모두 동의해주세요.");
			}
		}

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈


		return {
			checkedList,
			allCheck,
			nextStep,
			goBack,
			completed,
			allChecked,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,
			i18n,
		}
	},
}
</script>